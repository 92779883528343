<template>
  <div>
      <el-breadcrumb separator="/">
      <el-breadcrumb-item>添加app</el-breadcrumb-item>
    </el-breadcrumb>
    <hr style="margin-bottom:20px">
  <el-form ref="formData" :model="formData"  label-width="120px" :rules="rules">
    <el-form-item label="平台" prop="aa">
      <el-select placeholder="请选择平台" v-model="formData.versionType">
                <el-option
                  v-for="item in versionType"
                  :key="item.value"
                  :label="item.way"
                  :value="item.value"
                >
                </el-option>
              </el-select>
    </el-form-item>
    <el-form-item label="版本号">
      <el-input v-model="formData.versionNo"></el-input>
    </el-form-item>
    <el-form-item label="更新内容">
    <VueUeditorWrap v-model="formData.remark" :config="myConfig"  ref="myTextEditor"></VueUeditorWrap>
    </el-form-item>
    <el-form-item>
        <el-button size="mini" type="primary" style="margin:20px 20px 0 0" @click="sumitForm('formData')">确定</el-button>
        <el-button size="mini" type="primary" style="margin:20px 0" @click="$router.go(-1)">返回</el-button>
    </el-form-item>
  </el-form>
  </div>
</template>
<script>
import { addAppAPI,appDetailAPI} from "../../api/appManage";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
       components:{
   
    VueUeditorWrap
  },
    data(){
        return{
                   myConfig: {
        toolbars: [
          [
            'simpleupload', //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 400, // 初始容器高度
        initialFrameWidth: "60%", // 初始容器宽度
        //  serverUrl: "/api/config",
        serverUrl: "/config",//打包
        //          UEDITOR_HOME_URL: "/UEditor/",
        UEDITOR_HOME_URL: "/static/UEditor/",//打包
        maximumWords: "100000000",
      },
        versionType:[{value:1,way:'IOS'},
                {value:2,way:'Android'}],
            versionId:'',
            formData:{
                versionId:'0',
                versionNo:''
            },
            rules:{
                name:[{ message: '请填写完整', trigger: 'blur' }],
                // aa:[{ required: true, message: '请填写完整', trigger: 'blur' }],
                 email:[
                        { message:'请输入正确的邮箱', trigger: 'blur'},
                        {pattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,message: '请輸入正确的格式', trigger: 'blur'}
                    ],
                 phone:[
                        { message:'请输入正确的手机号', trigger: 'blur'},
                        {pattern:/^(?:(?:\+|00)86)?1[3-9]\d{9}$/,message: '请输入正确的手机号', trigger: 'blur'},
                        {max:11}
                    ],
            }
        }
    },
    created() {
    this.formData.versionId = this.$route.query.id
    if(this.formData.versionId!=='0'){
        this.getSupplierDetail()
    }
    },

    methods:{
    async getSupplierDetail(){
    const {data} = await appDetailAPI({versionId:this.formData.versionId})
    if(data.code==0){
        this.formData = data.data[0]
    }
    },
    async addSupplier(){
        let that = this
        const {data} = await addAppAPI(this.formData)
            if(data.code==0){
              this.$message({
              type: "success",
              message: data.msg
            });
            setTimeout(() => {
                that.$router.go(-1)
            }, 500);
            }else{
              this.$message({
              type: "error",
              message:data.msg
            });
            }
        },
    sumitForm(formData){
        this.$refs[formData].validate((valid) => {
          if (valid) {
            this.addSupplier()
          } else {
              this.addSupplier()
          }
        })
           
        },
    }
}
</script>
<style lang="less" scoped>
    .el-input__inner{
    width:300px !important;
}
.title{
    font-weight: bold;
    margin-left: -90px;
    span{
        height:13px;
        width: 5px;
        background-color: #3082fd;
        display: inline-block;
        margin-right: 5px;
    }

}
</style>